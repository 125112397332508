import React, { useEffect, useState } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { post } from '../../../../src/util/api';
import * as validators from '../../../util/validators';
import css from './RegisterForm.module.css';
import { Button, FieldTextInput } from '../../../components';

const RegisterForm = props => {
    const { intl } = props;
    const [shouldWait, setShouldWait] = useState(true);
    const [isRegistered, setIsRegistered] = useState(false);

    const onSubmit = values => {
        const requestOperatorOptions = {
            subject: intl.formatMessage({ id: 'RegisterForm.EmailSubject' }),
            text: `<p>${intl.formatMessage({ id: 'RegisterForm.EmailBodyDetails' })}</p>
          <p>${intl.formatMessage({ id: 'RegisterForm.Name' })}: ${values.userName}</p>
          <p>${intl.formatMessage({
                id: 'RegisterForm.Email',
            })}: ${values.userEmail.trim()}</p>
          `,
        };

        const requestOptions = {
            recipient: values.userEmail.trim(),
            subject: intl.formatMessage({ id: 'RegisterForm.EmailSubject' }),
            text: `<p>${intl.formatMessage({ id: 'RegisterForm.ThankYou' })}</p>
          <p>${intl.formatMessage({ id: 'RegisterForm.Name' })}: ${values.userName}</p>
          <p>${intl.formatMessage({
                id: 'RegisterForm.Email',
            })}: ${values.userEmail.trim()}</p>
          `,
        };

        post('/api/sendgrid-operator-email', requestOperatorOptions)
            .then(response => {
                post('/api/sendgrid-email', requestOptions)
                    .then(response => {
                        setIsRegistered(true);
                    })
            })
            .catch(e => {
            });
    };

    const required = validators.required(intl.formatMessage({ id: 'RegisterForm.Required' }));
    const valid = validators.emailFormatValid(
        intl.formatMessage({ id: 'RegisterForm.Invalid' })
    );

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            setShouldWait(false);
        }, 500);

        return () => clearTimeout(timeOutId);
    }, []);

    if (shouldWait) {
        return null;
    }

    return (
        <div className={css.popupWrapper}>

            {isRegistered ? (
                <div className={css.root}>
                    <p className={css.textBig}>
                        <FormattedMessage id="RegisterForm.ThankYou" />
                    </p>
                    <p className={css.text}>
                        <FormattedMessage id="RegisterForm.LookingForward" />
                    </p>
                </div>
            ) : (
                <div className={css.root}>
                    <label className={css.textBig}>
                        <FormattedMessage id="RegisterForm.HeaderText" />
                    </label>

                    <label className={css.text}>
                        <FormattedMessage id="RegisterForm.LaunchingSoon" />
                    </label>

                    <FinalForm
                        onSubmit={onSubmit}
                        render={fieldRenderProps => {
                            const { handleSubmit, invalid, pristine, submitting } = fieldRenderProps;
                            const submitDisabled = invalid || pristine || submitting;

                            return (
                                <form
                                    className={css.formContainer}
                                    onSubmit={e => {
                                        e.preventDefault();
                                        handleSubmit(e);
                                    }}
                                >
                                    <FieldTextInput
                                        inputRootClass={css.field}
                                        type="text"
                                        id="userName"
                                        name="userName"
                                        placeholder={intl.formatMessage({ id: 'RegisterForm.Name' })}
                                        validate={required}
                                    />

                                    <FieldTextInput
                                        inputRootClass={css.field}
                                        type="text"
                                        id="userEmail"
                                        name="userEmail"
                                        placeholder={intl.formatMessage({ id: 'RegisterForm.Email' })}
                                        validate={validators.composeValidators(required, valid)}
                                    />

                                    <Button className={css.submitButton} type="submit" disabled={submitDisabled}>
                                        {intl.formatMessage({ id: 'RegisterForm.Submit' })}
                                    </Button>
                                </form>
                            );
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default RegisterForm;