import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
// import coverVideo from './only_coming_soon.mp4'; //'./promoVideo.mp4';
// import fallbackImage from './landingPageFallback.png';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import {
  LayoutWrapperMain
} from '../../components';
import css from './UnauthedLandingPage.module.css';
import RegisterForm from './RegisterForm/RegisterForm';
import LogoImage from './logo-desktop-white.svg';

export const UnauthedLandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    pageAssetsData,
    inProgress,
    error,
    isAuthenticated,
  } = props;

  // useEffect(() => {
  //   if (typeof window !== 'undefined' && !isDev) {
  //     window.location.href = 'https://ihavethisfriend.co/';
  //   }
  // }, []);
  // if (!isDev) {
  //   return null;
  // }

  // const [transparent, setTransparent] = useState(true);
  // const [showVideo, setShowVideo] = useState(false);

  // const onScroll = () => {
  //   if (typeof window !== 'undefined') {
  //     if (window.scrollY > 50) {
  //       return setTransparent(false);
  //     } else if (window.scrollY <= 50) {
  //       return setTransparent(true);
  //     }
  //   }
  // };

  // const videoRef = React.useRef(null);

  // if (typeof window !== 'undefined') {
  //   useEffect(() => {
  //     window.addEventListener('scroll', onScroll);
  //     return () => window.removeEventListener('scroll', onScroll);
  //   }, [window, onScroll]);
  // }

  return (
    <LayoutWrapperMain>
      <div>
        {/*<div className={css.backgroundVideo}>
           <video
            className={showVideo ? css.videoActive : css.videoHidden}
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
            onPlay={() => {
              setShowVideo(true);
            }}
          >
            <source src={coverVideo} type="video/mp4" />
          </video>
          <img className={showVideo ? css.imageHidden : css.imageActive} src={fallbackImage}></img>
          <div className={css.darkOverlay}></div>
      */}

        {/* </div> */}

        <div className={css.backgroundLogo}>
          <img src={LogoImage} className={css.logoImage} />

        </div>


        <div className={css.centerSection}>
          {/* <NamedLink className={'logoLinkTransparent'} name="LandingPage">
            <Logo
              format="desktop"
              className={'unauthedLogo'}
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              transparent={true}
            />
          </NamedLink>
          */}
          <div className={css.buttons}>
            <RegisterForm intl={intl} />
          </div>

          {/* <a href="/login">
              <div className={classNames(css.loginButton, css.homepageButton)}>LOG IN</div>
            </a>
            <a href="/signup">
              <div className={classNames(css.signupButton, css.homepageButton)}>SIGN UP</div>
            </a>
          </div> */}
        </div>
      </div>
    </LayoutWrapperMain>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = false;//state.Auth;
  const { currentUserListing, currentUserListingFetched } = state.user;
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    pageAssetsData,
    inProgress,
    error,
    isAuthenticated,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const UnauthedLandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(UnauthedLandingPageComponent);

export default UnauthedLandingPage;
